<template>
    <div :class="itemClasses">
        <div :class="headerClasses" @click="toggle"
             :style="{background:isActive?' #F5F5F5':'',color:isActive?'#4954F7':''}">
            <slot></slot>
            <i :class="isActive?'el-icon-minus':'el-icon-plus'" style="font-size: 24px"></i>
        </div>
        <div :class="contentClass" :style="{height:isActive?'120px':'0',background:isActive?' #F5F5F5':''}">
            <div :class="boxClasses">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    const prefixCls = "ka-collapse";
    export default {
        name: "GkCollapseItem",
        data() {
            return {
                index: 0,
                isActive: false
            };
        },
        props: {
            name: {
                type: String
            },
            hideArrow: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            itemClasses() {
                return [
                    `${prefixCls}-item`,
                    {
                        [`${prefixCls}-item-active`]: this.isActive
                    }
                ];
            },
            // 定义header样式
            headerClasses() {
                return `${prefixCls}-header`;
            },
            contentClass() {
                return `${prefixCls}-content`;
            },
            boxClasses() {
                return `${prefixCls}-content-box`;
            }
        },
        created() {
            console.log("isActive", this.isActive)
        },
        methods: {
            toggle() {
                // console.log(this);
                this.$parent.toggle({
                    name: this.name || this.index,
                    isActive: this.isActive
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .ka-collapse-header {
        border-top: 1px solid #DBDBDB;
        padding: 30px 70px;
        display: flex;
        justify-content: space-between;
        &:hover {
            cursor: pointer;
        }
    }

    .ka-collapse-content {
        overflow: hidden;
        transition: all .2s;
        padding: 0 70px;
    }

    .ka-collapse-content-box {
        color: #888;
        width: 80%;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 2px;
    }
</style>
