import request from '@/axios/index.js'

export default {
    // 检测分类
    inspectionType() {
        return request({
            url: '/front/api/inspectionType',
            method: 'get'
        })
    },
    //检测列表
    inspectionList(type, pageNum, pageSize) {
        return request({
            url: '/front/api/inspectionList',
            method: 'get',
            params: {
                type,
                pageNum,
                pageSize: pageSize || 15
            }
        })
    }
}
