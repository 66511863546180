<template>
    <div class="page">
        <HeadContent>
            <img slot="bgImg" src="./images/inspection/组 31.png" style="margin-top: 70px">
            <div slot="title" style="width: 100%">检验检测</div>
        </HeadContent>
        <div style="width: 100%;background-color: #fbfbfb">
            <div class="container" style="margin-bottom: 50px">
                <div class="jyjc-nav-box">
                    <div class="jyjc-nav" v-if="typeList">
                        <div v-for="(item,index) in typeList" :key="index" @click="typeClick(index)"
                             :class="typeAc==index?'jcac':''">{{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="jc-tb">
                <div class="jc-tb-head">
                    <div class="col1">编号</div>
                    <div class="col2">检测项目</div>
                    <div class="col3">依据标准（方法）名称及编号（含年号）</div>
                </div>
                <div class="jc-tb-body">
                    <div class="jc-tb-body-col" v-for="(item,index) in jcList" :key="index">
                        <div class="col1">{{index+1}}</div>
                        <div class="col2">{{item.inspectionName}}</div>
                        <div class="col3">{{item.standard}}</div>
                    </div>
                    <div class="btn-box" style="margin-bottom: 60px">
                        <div class="com-more-btn" @click="getMoreData" v-if="page>=pageNum">加载更多</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="mtitle">
                常见问题
            </div>
            <div style="margin-top: 40px;">
                <GkCollapse>
                    <GkCollapseItem class="cj-coll">
                        <span>委托送检需要准备什么材料？</span>
                        <p slot="content">
                            委托单位应填写“重庆国科食品检测有限公司委托书”，具体说明委托品种、委托原因、检验方法、检验项目，并对填写内容负责。对申请用于行政审批事项的委托检验，应出具相关证明。如未书面告知我公司，擅自将收费委托检验报告用于行政审批事项，产生的后果由送检企业或个人自行承担。</p>
                    </GkCollapseItem>
                    <GkCollapseItem>
                        <span>委托送检需要送多少样品？</span>
                        <p slot="content">
                            除特殊情况外，样品量应为一次检验用量的三倍，普通样品0.5Kg以上（鸡爪等可食用部分较少样品增加0.5Kg送样量），要检测微生物的需同时达到六个独立包装以上，发证检验根据检测参数适量增加送样量。</p>
                    </GkCollapseItem>
                    <GkCollapseItem>
                        <span>委托送检可以快递送检吗？</span>
                        <p slot="content">可以的。客户需将足量的样品，与送检相关的表格（委托检测协议书）一起填写完，同样品一同寄到本公司，寄样品费用由客户自行支付。</p>
                    </GkCollapseItem>
                    <GkCollapseItem>
                        <span>委托送检最快多久出报告？</span>
                        <p slot="content">
                            本公司一般情况检测完出报告的周期是7个工作日（从样品到达本公司的第二个工作日算起，包括周六）。在检测参数允许的情况下，经本公司审核后，可允许加急（需额外支付加急费），但原则上不能低于3个工作日。</p>
                    </GkCollapseItem>
                    <GkCollapseItem>
                        <span>除委托检验外还提供什么服务？</span>
                        <p slot="content">有。本公司除检测服务外，还提供体系认证服务，企业标准备案，生产许可办理等服务。</p>
                    </GkCollapseItem>
                </GkCollapse>
            </div>
        </div>

        <!--    选择我们 s-->
        <zxhz></zxhz>
        <!--    选择我们 e-->
    </div>
</template>

<script>
    import jcApic from "@/api/mainService.js"
    import GkCollapseItem from "../../components/GkCollapse/GkCollapseItem";

    export default {
        components: {GkCollapseItem},
        data() {
            return {
                typeList: null,
                jcList: [],
                typeAc: 0,
                pageNum: 1,
                pageSize: 15,
                page: 0
            }
        },
        created() {
            this.getType()
        },
        methods: {
            typeClick(num) {
                this.pageNum = 1
                this.typeAc = num
                this.jcList = []
                this.getData(this.typeList[num])
            },
            getType() {
                jcApic.inspectionType().then(res => {
                    let list = res.data
                    this.typeList = list
                    if (list.length > 0) {
                        this.getData(list[0])
                    }
                })
            },
            getData(type, pageNum) {
                jcApic.inspectionList(type, pageNum, this.pageSize).then(res => {
                    let page = Math.ceil(res.total / this.pageSize)
                    this.jcList = this.jcList.concat(res.rows)
                })
            },
            getMoreData() {
                this.pageNum += 1
                this.getData(this.typeList[this.typeAc], this.pageNum)
            }
        }
    }
</script>

<style lang="scss">
    .jyjc-nav-box {
        max-width: 1200px;
        .jyjc-nav {
            padding-top: 20px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            color: #464646;
            div {
                margin-right: 70px;
                margin-bottom: 20px;
                padding: 6px 12px;
                white-space: nowrap;
                &:hover {
                    cursor: pointer;
                    //color: #1320E8;
                }
            }
        }
    }

    .jc-tb {
        width: 100%;
        .jc-tb-head {
            width: 100%;
            height: 68px;
            background: rgba(#888888, 0.2);
            display: flex;
            font-weight: 600;
            color: #464646;
            letter-spacing: 2px;
        }

        .jc-tb-body {
            background: #F5F5F5;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .jc-tb-body-col {
                display: flex;
                height: 68px;
            }
        }

        .col1 {
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #888888;
        }
        .col2 {
            box-sizing: border-box;
            padding-left: 40px;
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
            color: #606060;
        }
        .col3 {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            color: #888888;
        }

    }

    .jcac {
        background: #1320E8;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }

</style>
